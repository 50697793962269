import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { FaInfoCircle, FaCreditCard, FaSpinner, FaExclamationTriangle } from 'react-icons/fa';

const CheckoutPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  // Destructure state with default values
  const { 
    service = '', 
    price = 0, 
    uploadId = '', 
    files = [], 
    instructions = '' 
  } = location.state || {};

  // Validate required checkout information
  useEffect(() => {
    if (!location.state) {
      setError('Missing checkout information. Please start over.');
      return;
    }

    if (!service || !price || !uploadId) {
      setError('Invalid checkout information. Please try again.');
      return;
    }
  }, [location.state, service, price, uploadId]);

  // If there's an error, show error UI
  if (error) {
    return (
      <div className="min-h-screen bg-gray-100 flex items-center justify-center p-4">
        <div className="max-w-md w-full bg-white rounded-lg shadow-lg p-6">
          <div className="flex items-center justify-center text-red-500 mb-4">
            <FaExclamationTriangle size={48} />
          </div>
          <h2 className="text-2xl font-bold text-center text-gray-900 mb-4">
            Checkout Error
          </h2>
          <p className="text-gray-600 text-center mb-6">
            {error}
          </p>
          <div className="flex justify-center space-x-4">
            <button
              onClick={() => navigate('/pricing')}
              className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors"
            >
              Return to Pricing
            </button>
            <button
              onClick={() => navigate('/dashboard')}
              className="px-4 py-2 bg-gray-600 text-white rounded-md hover:bg-gray-700 transition-colors"
            >
              Go to Dashboard
            </button>
          </div>
        </div>
      </div>
    );
  }

  // PayPal configuration
  const initialOptions = {
    "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID,
    currency: "USD",
    intent: "capture",
  };

  const handleOrderCreate = (data, actions) => {
    return actions.order.create({
      purchase_units: [{
        amount: {
          currency_code: "USD",
          value: price.toFixed(2)
        },
        description: `Payment for ${service}`,
        custom_id: uploadId
      }],
      application_context: {
        shipping_preference: 'NO_SHIPPING'
      }
    });
  };

  const handlePayPalApprove = async (data, actions) => {
    try {
      setIsLoading(true);
      setError(null);

      const order = await actions.order.capture();

      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/payments/confirm-payment`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify({
          paypalOrderId: data.orderID,
          uploadId: uploadId,
          transactionDetails: order
        })
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Payment confirmation failed');
      }

      const confirmationData = await response.json();
      
      navigate('/confirmation', { 
        state: { 
          orderDetails: confirmationData.data.payment,
          uploadDetails: confirmationData.data.upload
        }
      });
    } catch (err) {
      console.error('Payment processing error:', err);
      setError(err.message || 'Payment processing failed. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-500 to-purple-600 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-3xl mx-auto bg-white rounded-2xl shadow-2xl overflow-hidden">
        {/* Order Summary */}
        <div className="bg-gray-50 px-6 py-4 border-b border-gray-200">
          <h1 className="text-3xl font-bold text-gray-900">Order Summary</h1>
        </div>
        
        <div className="p-6 space-y-6">
          {/* Service Details */}
          <div className="space-y-4 bg-gray-50 p-4 rounded-lg">
            <div className="flex justify-between items-center">
              <span className="text-lg font-semibold text-gray-700">Service:</span>
              <span className="text-lg text-gray-900">{service}</span>
            </div>
            <div className="flex justify-between items-center">
              <span className="text-lg font-semibold text-gray-700">Files:</span>
              <span className="text-lg text-gray-900">{files.length} file(s)</span>
            </div>
            {instructions && (
              <div className="flex flex-col">
                <span className="text-lg font-semibold text-gray-700 mb-2">Instructions:</span>
                <span className="text-gray-900 text-sm">{instructions}</span>
              </div>
            )}
            <div className="flex justify-between items-center pt-4 border-t border-gray-200">
              <span className="text-xl font-semibold text-gray-700">Total:</span>
              <span className="text-2xl font-bold text-green-600">${price.toFixed(2)} USD</span>
            </div>
          </div>

          {/* Loading State */}
          {isLoading && (
            <div className="flex justify-center items-center py-4">
              <FaSpinner className="animate-spin text-blue-600 text-2xl mr-2" />
              <span>Processing payment...</span>
            </div>
          )}

          {/* Error Message */}
          {error && (
            <div className="bg-red-50 border border-red-200 text-red-700 px-4 py-3 rounded-lg">
              <span className="block sm:inline">{error}</span>
            </div>
          )}

          {/* PayPal Integration */}
          <div className="mt-8">
            <h2 className="text-xl font-semibold mb-4 text-gray-800 flex items-center">
              <FaCreditCard className="mr-2 text-blue-500" />
              Pay with PayPal
            </h2>
            <PayPalScriptProvider options={initialOptions}>
              <PayPalButtons
                style={{
                  layout: "vertical",
                  color: "gold",
                  shape: "rect",
                }}
                createOrder={handleOrderCreate}
                onApprove={handlePayPalApprove}
                onError={(err) => {
                  console.error("PayPal Error:", err);
                  setError('Payment failed. Please try again or contact support.');
                }}
                onCancel={() => {
                  setError('Payment was cancelled. Please try again if you wish to complete your purchase.');
                }}
                disabled={isLoading}
              />
            </PayPalScriptProvider>
          </div>

          {/* Security Notice */}
          <div className="mt-4 text-sm text-gray-600 flex items-center justify-center">
            <FaInfoCircle className="mr-2 text-blue-500" />
            All transactions are secure and encrypted
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckoutPage;