import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useUser } from './UserContext';

const OAuthCallback = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { login } = useUser();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const token = params.get('token');
    
    if (token) {
      // Store token
      localStorage.setItem('token', token);

      // Fetch user data
      fetch(`${process.env.REACT_APP_BACKEND_URL}/api/auth/verify`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
      .then(res => {
        if (!res.ok) {
          throw new Error('Failed to fetch user data');
        }
        return res.json();
      })
      .then(data => {
        login(data.user);
        navigate('/dashboard');
      })
      .catch(err => {
        console.error('Error fetching user data:', err);
        navigate('/login');
      });
    } else {
      navigate('/login');
    }
  }, [navigate, login, location.search]);

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50">
      <div className="text-center">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-600 mx-auto mb-4"></div>
        <p className="text-xl text-gray-600">Completing sign in...</p>
      </div>
    </div>
  );
};

export default OAuthCallback;