import React from 'react';
import { useLocation } from 'react-router-dom';

const ConfirmationPage = () => {
  const location = useLocation();
  const { orderDetails } = location.state || {};

  return (
    <div className="min-h-screen bg-gray-100 flex items-center justify-center">
      <div className="bg-white p-8 rounded-lg shadow-xl max-w-md w-full">
        <h1 className="text-3xl font-bold text-center text-green-600 mb-4">
          Thank You for Your Order!
        </h1>
        <p className="text-center text-gray-700 mb-4">
          Your order has been successfully processed.
        </p>
        <p className="text-center text-gray-700">
          Order ID: {orderDetails?.id}
        </p>
      </div>
    </div>
  );
};

export default ConfirmationPage;