import React from 'react';
import { useParams } from 'react-router-dom';

const algoAssistServices = {
    codingAssignments: {
      title: "Coding Assignments",
      description: "AI-powered assistance for individual coding assignments across various programming languages.",
      price: 24.99,
      roadmap: [
        "Submit your coding assignment",
        "Our AI analyzes the requirements",
        "We generate a tailored solution",
        "Expert review ensures quality",
        "You receive the complete solution",
        "24/7 support for any questions"
      ]
    },
    codingProjects: {
      title: "Coding Projects",
      description: "Comprehensive assistance for larger coding projects, including planning, implementation, and documentation.",
      price: 49.99,
      roadmap: [
        "Initial project consultation",
        "AI-assisted project planning",
        "Iterative development process",
        "Regular progress updates",
        "Comprehensive testing phase",
        "Final project delivery with documentation",
        "Post-project support"
      ]
    },
    writtenAssignments: {
      title: "Written Assignments",
      description: "AI assistance for computer science-related written assignments, ensuring clarity, accuracy, and proper citations.",
      price: 19.99,
      roadmap: [
        "Submit your assignment brief",
        "AI-powered research and outline generation",
        "Draft creation with technical accuracy",
        "Citations and references compilation",
        "Plagiarism check and language refinement",
        "Final review and formatting",
        "Delivery of the completed assignment"
      ]
    }
};

const sharedComponents = {
    userInterface: [
      "Login and Authentication System",
      "Service Selection Dashboard",
      "File Upload and Management",
      "Progress Tracking",
      "Result Viewer",
      "Feedback and Rating System"
    ],
    backendSystems: [
      "User Management",
      "Payment Processing",
      "AI Model Integration",
      "Data Storage and Retrieval",
      "Security and Encryption",
      "API Gateway"
    ],
    aiAndMachineLearning: [
      "Natural Language Processing",
      "Code Generation and Analysis",
      "Plagiarism Detection",
      "Content Summarization",
      "Recommendation Systems"
    ]
};

const pricingModalLink = "#pricing-modal";

const Learn = () => {
    const { service } = useParams();
    const serviceData = algoAssistServices[service];

    if (!serviceData) {
        return <div>Service not found</div>;
    }

    return (
        <div className="container mx-auto px-4 py-8">
            <h1 className="text-3xl font-bold mb-4">{serviceData.title}</h1>
            <p className="text-xl mb-4">{serviceData.description}</p>
            <h2 className="text-2xl font-semibold mb-2">Price: ${serviceData.price}</h2>
            <h3 className="text-xl font-semibold mt-6 mb-2">Service Roadmap:</h3>
            <ol className="list-decimal pl-5 mb-6">
                {serviceData.roadmap.map((step, index) => (
                    <li key={index} className="mb-2">{step}</li>
                ))}
            </ol>
        </div>
    );
};

export default Learn;

export {
    algoAssistServices,
    sharedComponents,
    pricingModalLink
};