import React, { useState, useEffect } from 'react';
import {
  FaLaptopCode,
  FaProjectDiagram,
  FaCheckCircle,
  FaClock,
  FaShieldAlt,
  FaQuoteLeft,
  FaChevronLeft,
  FaChevronRight,
  FaPenFancy,
  FaUserGraduate,
  FaStar,
  FaUsers,
  FaEnvelope,
  FaPhone,
  FaMapMarkerAlt,
  FaBars,
  FaTimes,
  FaLock,
  FaSearch,
  FaMedal,
  FaUser,
} from 'react-icons/fa';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Link } from 'react-scroll';
import { useUser } from './UserContext';


// Testimonials Data
const testimonials = [
  {
    quote: "AlgoAssist AI saved me from missing my assignment deadline! The 24-hour delivery guarantee is real, and I ended up with a perfect score. Highly recommended!",
    author: "Sarah P., Computer Science Student"
  },
  {
    quote: "I was struggling with a complicated coding project, and AlgoAssist AI provided the help I needed in no time. The project was delivered perfectly and got me a 95%! I couldn't be happier!",
    author: "John D., Software Engineering Student"
  },
  {
    quote: "The coding solutions from AlgoAssist AI are spot-on. I've used their services multiple times, and they've always delivered high-quality work that earns top marks.",
    author: "Emily R., Data Science Student"
  },
  {
    quote: "The AI-powered solutions are not just fast, they're accurate! My assignment was flawless, and I got 100%. I'll definitely use this service again.",
    author: "Carlos M., Web Development Student"
  },
  {
    quote: "I was hesitant at first, but AlgoAssist AI delivered my project in less than 24 hours and the quality was amazing. I scored a 98% and couldn't believe how easy the process was.",
    author: "Alex B., Information Systems Student"
  },
  {
    quote: "AlgoAssist AI is a lifesaver! I was panicking about my project deadline, but their team got it done perfectly, on time, and I scored 95%. Definitely worth every penny!",
    author: "Maya S., Computer Programming Student"
  },
  {
    quote: "This service is incredible! I got 100% on my coding assignment, and it was delivered exactly as promised—on time and with perfect execution.",
    author: "Lucas T., Software Development Student"
  },
  {
    quote: "Fast, reliable, and professional! AlgoAssist AI helped me with my final year project, and I couldn't be happier with the result. My project passed with flying colors!",
    author: "Sophia K., Artificial Intelligence Student"
  },
  {
    quote: "I needed help with a tough coding project, and AlgoAssist AI delivered exceptional work. The team verified every detail, and I ended up with a 97%!",
    author: "Liam J., Computer Engineering Student"
  },
  {
    quote: "AlgoAssist AI exceeded my expectations. The 90-100% guarantee is real—I got a perfect score on my assignment, and it was delivered way before the deadline!",
    author: "Olivia M., Robotics Engineering Student"
  }
];

// Testimonial Carousel Component
const TestimonialCarousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
    }, 5000);

    return () => clearInterval(intervalId);
  }, []);

  const goToPrevious = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + testimonials.length) % testimonials.length
    );
  };

  const goToNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
  };

  return (
    <div className="relative w-full max-w-4xl mx-auto px-4 py-16">
      {/* Testimonial Card */}
      <div className="relative bg-white shadow-lg rounded-lg p-8 transition-all duration-500 hover:shadow-xl">
        <div className="absolute top-0 left-0 w-20 h-20 bg-blue-600 rounded-tl-lg rounded-br-lg flex items-center justify-center">
          <FaQuoteLeft className="text-3xl text-white" />
        </div>
        <div className="ml-24 mt-4">
          <p className="text-xl italic mb-6 text-gray-800 leading-relaxed">
            "{testimonials[currentIndex].quote}"
          </p>
          <div>
            <p className="font-semibold text-gray-800">{testimonials[currentIndex].author.split(',')[0]}</p>
            <p className="text-sm text-gray-600">{testimonials[currentIndex].author.split(',')[1]}</p>
          </div>
        </div>
      </div>


      {/* Navigation Buttons */}
      <div className="absolute bottom-4 right-4 flex space-x-2">
        <button
          onClick={goToPrevious}
          className="bg-blue-600 text-white rounded-full p-2 hover:bg-blue-700 focus:outline-none transition-transform transform hover:scale-110 duration-300"
          aria-label="Previous Testimonial"
        >
          <FaChevronLeft size={20} />
        </button>
        <button
          onClick={goToNext}
          className="bg-blue-600 text-white rounded-full p-2 hover:bg-blue-700 focus:outline-none transition-transform transform hover:scale-110 duration-300"
          aria-label="Next Testimonial"
        >
          <FaChevronRight size={20} />
        </button>
      </div>

      {/* Progress Indicator */}
      <div className="absolute bottom-4 left-4 flex space-x-1">
        {testimonials.map((_, index) => (
          <div
            key={index}
            className={`w-2 h-2 rounded-full transition-all duration-300 ${
              currentIndex === index ? 'bg-blue-600 w-4' : 'bg-gray-300'
            }`}
          ></div>
        ))}
      </div>
    </div>
  );
};

// Statistics Section
const StatisticsSection = () => {
  return (
    <section className="bg-gray-100 py-20">
      <div className="container mx-auto px-4">
        <h2 className="text-4xl font-bold text-center text-gray-800 mb-12">
          Our Impact in Numbers
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          <StatCard
            icon={<FaUserGraduate className="text-5xl text-blue-600" />}
            number="50,000+"
            text="Students Helped"
          />
          <StatCard
            icon={<FaStar className="text-5xl text-blue-600" />}
            number="4.7/5"
            text="Average Rating"
          />
          <StatCard
            icon={<FaClock className="text-5xl text-blue-600" />}
            number="97%"
            text="On-Time Delivery"
          />
          <StatCard
            icon={<FaUsers className="text-5xl text-blue-600" />}
            number="200+"
            text="Expert Reviewers"
          />
        </div>
      </div>
    </section>
  );
};

// Stat Card Component
const StatCard = ({ icon, number, text }) => {
  return (
    <div className="bg-white rounded-lg shadow-lg p-6 text-center transition-transform transform hover:scale-105 hover:shadow-xl duration-300">
      <div className="flex justify-center mb-4">{icon}</div>
      <h3 className="text-3xl font-bold text-gray-800 mb-2">{number}</h3>
      <p className="text-gray-600">{text}</p>
    </div>
  );
};

// FAQ Item Component
const FAQItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="bg-white rounded-lg shadow-md overflow-hidden transition-transform transform hover:scale-105 hover:shadow-lg duration-300">
      <button
        className="w-full text-left p-6 focus:outline-none hover:bg-gray-50 transition-colors duration-200 flex justify-between items-center"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className="font-semibold text-gray-800 text-lg">{question}</span>
        <svg
          className={`w-6 h-6 text-blue-600 transform transition-transform duration-200 ${
            isOpen ? 'rotate-180' : ''
          }`}
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
        </svg>
      </button>
      {isOpen && (
        <div className="p-6 bg-gray-50 transition-all duration-500 ease-in-out">
          <p className="text-gray-600 leading-relaxed">{answer}</p>
        </div>
      )}
    </div>
  );
};

// FAQ Data
const faqData = [
  {
    question: "How does AlgoAssist AI ensure the quality of its solutions?",
    answer: "Our AI-powered system, combined with expert human reviewers, ensures that all solutions are accurate, efficient, and tailored to your specific assignment requirements."
  },
  {
    question: "What if I'm not satisfied with the solution provided?",
    answer: "We offer a satisfaction guarantee. If you're not happy with the solution, we'll revise it for free or provide a full refund."
  },
  {
    question: "How quickly can I receive my completed assignment?",
    answer: "We guarantee delivery within 24 hours for most assignments. For larger projects, we'll provide a custom timeline."
  },
  {
    question: "Is using AlgoAssist AI considered cheating?",
    answer: "AlgoAssist AI is designed as a learning tool to help you understand complex coding concepts. We encourage you to use our solutions as a guide to enhance your learning, not as a substitute for your own work."
  },
  {
    question: "Can I request help with any programming language?",
    answer: "Yes, we cover a wide range of programming languages including Python, Java, C++, JavaScript, and many more. If you have a specific language requirement, just let us know."
  },
  {
    question: "How do I submit my assignment for help?",
    answer: "After selecting your service, you'll be directed to our secure upload page where you can provide your assignment details and any relevant files."
  },
  {
    question: "What kind of support do you offer for larger coding projects?",
    answer: "For larger projects, we offer comprehensive support including planning, coding, testing, and documentation. We can assist with various stages of your project as needed."
  },
  {
    question: "How does pricing work for different types of assignments?",
    answer: "Pricing varies based on the complexity and length of the assignment. We offer transparent pricing which you can view before submitting your project."
  },
  {
    question: "Is my personal and assignment information kept confidential?",
    answer: "Absolutely. We have strict privacy policies in place to ensure that all your personal information and assignment details are kept completely confidential."
  },
  {
    question: "Can I communicate with the AI or reviewers working on my assignment?",
    answer: "While direct communication isn't available, you can always reach out to our support team with any questions or additional information for your assignment."
  }
];

// Main App Component
const App = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const navigate = useNavigate();
  const { user, logout } = useUser();

  const handleGetStarted = () => {
    if (user) {
      navigate('/pricing');
    } else {
      localStorage.setItem('postLoginAction', 'pricing');
      navigate('/login');
    }
  };

  const handleUserMenuClick = () => {
    navigate('/dashboard');
  };

  const handleLogout = () => {
    logout();
    navigate('/');
  };

  // Desktop User Menu
  const UserMenu = () => (
    <div className="relative group">
      <button
        className="flex items-center space-x-2 text-gray-700 hover:text-blue-600 transition duration-300"
      >
        <FaUser className="text-blue-600" />
        <span>{user.name}</span>
      </button>
      <div className="absolute right-0 mt-2 py-2 w-48 bg-white rounded-md shadow-xl opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all duration-300">
        <button
          onClick={handleUserMenuClick}
          className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
        >
          Dashboard
        </button>
        <button
          onClick={handleLogout}
          className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
        >
          Logout
        </button>
      </div>
    </div>
  );

  return (
    <div className="font-sans bg-white text-gray-800">
      {/* Navigation Bar */}
      <nav className="bg-white shadow fixed w-full z-20">
        <div className="container mx-auto flex justify-between items-center p-4">
          <RouterLink to="/" className="flex items-center">
            <img src="/logo_no_background.png" alt="AlgoAssist AI Logo" className="h-10 w-auto mr-2" />
            <span className="text-2xl font-semibold">AlgoAssist AI</span>
          </RouterLink>
          <div className="hidden md:flex space-x-6 items-center">
            <Link
              to="guarantees"
              smooth={true}
              duration={500}
              className="hover:text-blue-600 cursor-pointer transition duration-300"
            >
              Guarantees
            </Link>
            <Link
              to="testimonials"
              smooth={true}
              duration={500}
              className="hover:text-blue-600 cursor-pointer transition duration-300"
            >
              Testimonials
            </Link>
            <Link
              to="faq"
              smooth={true}
              duration={500}
              className="hover:text-blue-600 cursor-pointer transition duration-300"
            >
              FAQ
            </Link>
            <Link
              to="contact"
              smooth={true}
              duration={500}
              className="hover:text-blue-600 cursor-pointer transition duration-300"
            >
              Contact
            </Link>

            {user ? (
              <UserMenu />
            ) : (
              <button
                onClick={() => navigate('/login')}
                className="bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700 transition-transform transform hover:scale-105"
              >
                Login/Signup
              </button>
            )}
          </div>

          {/* Mobile Menu Button */}
          <div className="md:hidden">
            <button
              onClick={() => setMenuOpen(!isMenuOpen)}
              className="text-gray-600 hover:text-blue-600 focus:outline-none"
              aria-label="Toggle Menu"
            >
              {isMenuOpen ? <FaTimes size={24} /> : <FaBars size={24} />}
            </button>
          </div>
        </div>

        {/* Mobile Menu */}
        {isMenuOpen && (
          <div className="md:hidden bg-white shadow-lg">
            <div className="flex flex-col items-center space-y-4 py-4">
              <Link
                to="guarantees"
                smooth={true}
                duration={500}
                className="hover:text-blue-600 cursor-pointer"
                onClick={() => setMenuOpen(false)}
              >
                Guarantees
              </Link>
              <Link
                to="testimonials"
                smooth={true}
                duration={500}
                className="hover:text-blue-600 cursor-pointer"
                onClick={() => setMenuOpen(false)}
              >
                Testimonials
              </Link>
              <Link
                to="faq"
                smooth={true}
                duration={500}
                className="hover:text-blue-600 cursor-pointer"
                onClick={() => setMenuOpen(false)}
              >
                FAQ
              </Link>
              <Link
                to="contact"
                smooth={true}
                duration={500}
                className="hover:text-blue-600 cursor-pointer"
                onClick={() => setMenuOpen(false)}
              >
                Contact
              </Link>

              {user ? (
                <div className="flex flex-col items-center space-y-2">
                  <div className="text-gray-700">
                    <FaUser className="inline mr-2 text-blue-600" />
                    {user.name}
                  </div>
                  <button
                    onClick={() => {
                      navigate('/dashboard');
                      setMenuOpen(false);
                    }}
                    className="text-blue-600 hover:text-blue-700"
                  >
                    Dashboard
                  </button>
                  <button
                    onClick={() => {
                      handleLogout();
                      setMenuOpen(false);
                    }}
                    className="bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700"
                  >
                    Logout
                  </button>
                </div>
              ) : (
                <button
                  onClick={() => {
                    navigate('/login');
                    setMenuOpen(false);
                  }}
                  className="bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700"
                >
                  Login/Signup
                </button>
              )}
            </div>
          </div>
        )}
      </nav>

      {/* Header */}
      <header className="pt-32 pb-24 bg-gray-50">
        <div className="container mx-auto flex flex-col md:flex-row items-center md:space-x-12">
          <div className="md:w-1/2 text-center md:text-left">
            <h1 className="text-5xl font-bold mb-6">
              Welcome to <span className="text-blue-600">AlgoAssist AI</span>
            </h1>
            <p className="text-xl mb-8">
              Your AI-powered coding assignment helper then which are expert reviewed, ensuring top marks in record time. 
              
              Undetectable AI content and zero plagiarism guaranteed.
            </p>
            <button
              onClick={handleGetStarted}
              className="bg-blue-600 text-white font-semibold py-3 px-8 rounded-md hover:bg-blue-700 transition duration-300 transform hover:scale-105 shadow-lg hover:shadow-2xl"
            >
              Get Started
            </button>
            {/* Security Badges */}
            <div className="mt-6 flex space-x-4 items-center justify-center md:justify-start">
              <FaLock className="text-green-500 text-2xl" />
              <span className="text-sm text-gray-600">Secure Payment Processing</span>
            </div>
          </div>
          <div className="md:w-1/2 mt-12 md:mt-0">
            <img src="/logo_no_background.png" alt="AlgoAssist AI Logo" className="mx-auto md:ml-auto h-64 w-auto transition-transform transform hover:scale-105 duration-300" />
          </div>
        </div>
      </header>

      {/* Main Content */}
      <main>
       {/* Services Section */}
        <section id="services" className="py-20 bg-gray-50">
          <div className="container mx-auto">
            <h2 className="text-4xl font-bold text-center mb-16">
              Our <span className="text-blue-600">Services</span>
            </h2>
            <div className="grid md:grid-cols-3 gap-12">
              {/* Coding Assignments */}
              <div className="bg-white p-8 rounded-lg shadow transition-transform duration-500 ease-in-out hover:scale-105 hover:shadow-2xl flex flex-col justify-between">
                <div>
                  <div className="flex justify-center mb-6">
                    <FaLaptopCode className="text-blue-600 text-6xl" />
                  </div>
                  <h3 className="text-2xl font-semibold mb-4 text-center">Coding Assignments</h3>
                  <p className="text-gray-600 mb-6 text-center">
                    Get expert assistance with your coding assignments. We guarantee fast, accurate solutions tailored to your needs.
                  </p>
                </div>
                <div className="mt-auto text-center">
                  <RouterLink
                    to="/learn/codingAssignments"
                    className="bg-blue-600 text-white px-6 py-2 rounded-md transition-transform duration-500 ease-in-out hover:bg-blue-700 transform hover:scale-110 shadow-lg hover:shadow-2xl inline-block"
                  >
                    Learn More
                  </RouterLink>
                </div>
              </div>
              {/* Coding Projects */}
              <div className="bg-white p-8 rounded-lg shadow transition-transform duration-500 ease-in-out hover:scale-105 hover:shadow-2xl flex flex-col justify-between">
                <div>
                  <div className="flex justify-center mb-6">
                    <FaProjectDiagram className="text-blue-600 text-6xl" />
                  </div>
                  <h3 className="text-2xl font-semibold mb-4 text-center">Coding Projects</h3>
                  <p className="text-gray-600 mb-6 text-center">
                    Need help with a larger coding project? We provide in-depth project assistance, ensuring success from start to finish.
                  </p>
                </div>
                <div className="mt-auto text-center">
                  <RouterLink
                    to="/learn/codingProjects"
                    className="bg-blue-600 text-white px-6 py-2 rounded-md transition-transform duration-500 ease-in-out hover:bg-blue-700 transform hover:scale-110 shadow-lg hover:shadow-2xl inline-block"
                  >
                    Learn More
                  </RouterLink>
                </div>
              </div>
              {/* Written Assignments */}
              <div className="bg-white p-8 rounded-lg shadow transition-transform duration-500 ease-in-out hover:scale-105 hover:shadow-2xl flex flex-col justify-between">
                <div>
                  <div className="flex justify-center mb-6">
                    <FaPenFancy className="text-blue-600 text-6xl" />
                  </div>
                  <h3 className="text-2xl font-semibold mb-4 text-center">Written Assignments</h3>
                  <p className="text-gray-600 mb-6 text-center">
                    Expert assistance with computer science written assignments, ensuring clarity and top grades.
                  </p>
                </div>
                <div className="mt-auto text-center">
                  <RouterLink
                    to="/learn/writtenAssignments"
                    className="bg-blue-600 text-white px-6 py-2 rounded-md transition-transform duration-500 ease-in-out hover:bg-blue-700 transform hover:scale-110 shadow-lg hover:shadow-2xl inline-block"
                  >
                    Learn More
                  </RouterLink>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Statistics Section */}
        <StatisticsSection />

        {/* Guarantees Section */}
        <section id="guarantees" className="py-20 bg-white">
          <div className="container mx-auto">
            <h2 className="text-4xl font-bold text-center mb-16">
              Our <span className="text-blue-600">Guarantees</span>
            </h2>
            <div className="grid md:grid-cols-3 gap-12">
              
              {/* AI-Powered Solutions */}
              <div className="bg-gray-50 p-8 rounded-lg shadow hover:shadow-xl transition-shadow duration-300 transform hover:scale-105">
                <div className="flex justify-center mb-6">
                  <FaCheckCircle className="text-blue-600 text-6xl" />
                </div>
                <h3 className="text-2xl font-semibold mb-4 text-center">
                  AI-Powered Solutions
                </h3>
                <p className="text-gray-600 text-center">
                  Get accurate, fast solutions tailored to your assignments.
                </p>
              </div>
              
              {/* Undetectable AI Content */}
              <div className="bg-gray-50 p-8 rounded-lg shadow hover:shadow-xl transition-shadow duration-300 transform hover:scale-105">
                <div className="flex justify-center mb-6">
                  <FaShieldAlt className="text-blue-600 text-6xl" />
                </div>
                <h3 className="text-2xl font-semibold mb-4 text-center">
                  Undetectable AI Content
                </h3>
                <p className="text-gray-600 text-center">
                  Our AI-generated content is undetectable and blends seamlessly with human work.
                </p>
              </div>

              {/* Zero Plagiarism Guaranteed */}
              <div className="bg-gray-50 p-8 rounded-lg shadow hover:shadow-xl transition-shadow duration-300 transform hover:scale-105">
                <div className="flex justify-center mb-6">
                  <FaLock className="text-blue-600 text-6xl" />
                </div>
                <h3 className="text-2xl font-semibold mb-4 text-center">
                  Zero Plagiarism Guaranteed
                </h3>
                <p className="text-gray-600 text-center">
                  We guarantee 100% original content with no plagiarism, verified by advanced tools.
                </p>
              </div>

              {/* Expert Reviewed */}
              <div className="bg-gray-50 p-8 rounded-lg shadow hover:shadow-xl transition-shadow duration-300 transform hover:scale-105">
                <div className="flex justify-center mb-6">
                  <FaSearch className="text-blue-600 text-6xl" />
                </div>
                <h3 className="text-2xl font-semibold mb-4 text-center">
                  Expert Reviewed
                </h3>
                <p className="text-gray-600 text-center">
                  All content is reviewed by experts to ensure accuracy and quality.
                </p>
              </div>
              
              {/* Delivered in 24 Hours */}
              <div className="bg-gray-50 p-8 rounded-lg shadow hover:shadow-xl transition-shadow duration-300 transform hover:scale-105">
                <div className="flex justify-center mb-6">
                  <FaClock className="text-blue-600 text-6xl" />
                </div>
                <h3 className="text-2xl font-semibold mb-4 text-center">Delivered in 24 Hours</h3>
                <p className="text-gray-600 text-center">
                  We guarantee project delivery within 24 hours, verified by our tech team.
                </p>
              </div>

              {/* Guaranteed 90-100% Marks */}
              <div className="bg-gray-50 p-8 rounded-lg shadow hover:shadow-xl transition-shadow duration-300 transform hover:scale-105">
                <div className="flex justify-center mb-6">
                  <FaMedal className="text-blue-600 text-6xl" />
                </div>
                <h3 className="text-2xl font-semibold mb-4 text-center">
                  Guaranteed 90-100% Marks
                </h3>
                <p className="text-gray-600 text-center">
                  All projects are carefully checked to ensure they meet instructions and result in top grades.
                </p>
              </div>
            </div>
          </div>
        </section>

        {/* Testimonials Section */}
        <section id="testimonials" className="bg-gray-50 py-20">
          <div className="container mx-auto">
            <h2 className="text-4xl font-bold text-center mb-16">
              What Our <span className="text-blue-600">Students</span> Say
            </h2>
            <TestimonialCarousel />
          </div>
        </section>

        {/* FAQ Section */}
        <section id="faq" className="py-20 bg-white">
          <div className="container mx-auto px-4">
            <h2 className="text-4xl font-bold text-center mb-16">
              Frequently Asked <span className="text-blue-600">Questions</span>
            </h2>
            <div className="grid md:grid-cols-2 gap-12 max-w-5xl mx-auto">
              {faqData.map((item, index) => (
                <FAQItem key={index} question={item.question} answer={item.answer} />
              ))}
            </div>
          </div>
        </section>

        {/* Contact Section */}
        <section id="contact" className="py-20 bg-gray-50">
          <div className="container mx-auto px-4">
            <h2 className="text-4xl font-bold text-center mb-16">
              Get in <span className="text-blue-600">Touch</span>
            </h2>
            <div className="grid md:grid-cols-2 gap-16 items-start max-w-5xl mx-auto">
              <div className="space-y-8">
                <div className="flex items-start space-x-4">
                  <FaEnvelope className="text-3xl text-blue-600 mt-1" />
                  <div>
                    <h3 className="text-xl font-semibold">Email Us</h3>
                    <a href="mailto:support@algoassistai.com" className="text-blue-600 hover:underline">
                      support@algoassistai.com
                    </a>
                  </div>
                </div>
                <div className="flex items-start space-x-4">
                  <FaPhone className="text-3xl text-blue-600 mt-1" />
                  <div>
                    <h3 className="text-xl font-semibold">Call Us</h3>
                    <p>+1 (555) 123-4567</p>
                  </div>
                </div>
                <div className="flex items-start space-x-4">
                  <FaMapMarkerAlt className="text-3xl text-blue-600 mt-1" />
                  <div>
                    <h3 className="text-xl font-semibold">Visit Us</h3>
                    <p>123 AI Street, Tech City, TC 12345</p>
                  </div>
                </div>
                {/* Removed social links under "Follow Us" as per request */}
              </div>
              <form className="space-y-6 bg-white p-8 rounded-lg shadow transform transition-transform duration-300 hover:scale-105">
                <input
                  type="text"
                  placeholder="Your Name"
                  className="w-full p-4 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600 transition-shadow duration-300"
                />
                <input
                  type="email"
                  placeholder="Your Email"
                  className="w-full p-4 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600 transition-shadow duration-300"
                />
                <textarea
                  placeholder="Your Message"
                  rows="6"
                  className="w-full p-4 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600 transition-shadow duration-300"
                ></textarea>
                <button
                  type="submit"
                  className="w-full bg-blue-600 text-white font-semibold py-3 px-6 rounded-md hover:bg-blue-700 transition duration-300 transform hover:scale-105 shadow-lg hover:shadow-2xl"
                >
                  Send Message
                </button>
              </form>
            </div>
          </div>
        </section>

        {/* Back to Top Button */}
        <button
          onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
          className="fixed bottom-8 right-8 bg-blue-600 text-white p-3 rounded-full shadow-lg hover:bg-blue-700 transition duration-300 transform hover:scale-110"
          aria-label="Back to Top"
        >
          <svg
            className="w-6 h-6 transform -rotate-90"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 12h14M12 5l7 7-7 7" />
          </svg>
        </button>
      </main>

        {/* Footer */}
        <footer className="bg-gray-800 text-white py-12">
        <div className="container mx-auto text-center">
          <p className="mb-6">&copy; {new Date().getFullYear()} AlgoAssist AI. All rights reserved.</p>
          <div className="flex justify-center space-x-6">
            <RouterLink to="/privacy" className="hover:text-gray-400 transition-colors duration-300">
              Privacy Policy
            </RouterLink>
            <RouterLink to="/terms" className="hover:text-gray-400 transition-colors duration-300">
              Terms of Service
            </RouterLink>
            <RouterLink to="/contact" className="hover:text-gray-400 transition-colors duration-300">
              Contact Us
            </RouterLink>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default App;