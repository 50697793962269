import React, { useState, useCallback, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { useLocation, useNavigate } from 'react-router-dom';
import { 
  FaCloudUploadAlt, 
  FaFileAlt, 
  FaTrashAlt, 
  FaCheckCircle, 
  FaInfoCircle, 
  FaUser, 
  FaEnvelope, 
  FaPhone 
} from 'react-icons/fa';

const UploadPage = () => {
  const [files, setFiles] = useState([]);
  const [instructions, setInstructions] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const service = searchParams.get('service');

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/login');
    }
  }, [navigate]);

  const onDrop = useCallback((acceptedFiles) => {
    setFiles(prevFiles => [...prevFiles, ...acceptedFiles]);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ 
    onDrop,
    accept: {}, // Accept all file types
    maxSize: 104857600 // 100MB limit
  });

  const removeFile = (fileToRemove) => {
    setFiles(files.filter(file => file !== fileToRemove));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Basic validation
    if (!name.trim()) {
      alert('Please enter your name.');
      return;
    }
    if (!email.trim()) {
      alert('Please enter your email.');
      return;
    }
    // Simple email regex for validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      alert('Please enter a valid email address.');
      return;
    }
    if (!phoneNumber.trim()) {
      alert('Please enter your phone number.');
      return;
    }
    if (files.length === 0) {
      alert('Please upload at least one file.');
      return;
    }
    setIsSubmitted(true);
  };

  const handleCheckout = () => {
    navigate('/checkout', {
      state: {
        service: serviceDetails.title,
        price: serviceDetails.price,
        files: files,
        instructions: instructions,
        name: name,
        email: email,
        phoneNumber: phoneNumber
      }
    });
  };

  const getServiceDetails = (service) => {
    switch (service) {
      case 'coding-assignments ':
        return { title: 'Coding Assignments', price: 14.99 };
      case 'coding-projects':
        return { title: 'Coding Projects', price: 49.99 };
      case 'written-assignments':
        return { title: 'Written Assignments', price: 19.99 };
      default:
        return { title: 'Unknown Service', price: 0 };
    }
  };

  const serviceDetails = getServiceDetails(service);

  return (
    <div className="min-h-screen bg-gray-200 py-12 px-4 sm:px-6 lg:px-8"> {/* Changed background to light gray */}
      <div className="max-w-4xl mx-auto bg-white rounded-2xl shadow-2xl overflow-hidden">
        {/* Header */}
        <div className="bg-gray-50 py-6 px-8 border-b border-gray-200">
          <h1 className="text-3xl font-extrabold text-gray-900">Upload Files for {serviceDetails.title}</h1>
        </div>
        
        {/* Form Content */}
        <div className="p-8">
          <form onSubmit={handleSubmit}>
            {/* User Information */}
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-6">
              {/* Name */}
              <div className="flex flex-col">
                <label htmlFor="name" className="flex items-center text-gray-700 mb-2">
                  <FaUser className="mr-2 text-blue-600" />
                  Name
                </label>
                <input
                  type="text"
                  id="name"
                  placeholder="Your Name"
                  className="p-4 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600 transition-shadow duration-300"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
              </div>
              
              {/* Email */}
              <div className="flex flex-col">
                <label htmlFor="email" className="flex items-center text-gray-700 mb-2">
                  <FaEnvelope className="mr-2 text-blue-600" />
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  placeholder="Your Email"
                  className="p-4 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600 transition-shadow duration-300"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
              
              {/* Phone Number */}
              <div className="flex flex-col">
                <label htmlFor="phone" className="flex items-center text-gray-700 mb-2">
                  <FaPhone className="mr-2 text-blue-600" />
                  Phone Number
                </label>
                <input
                  type="tel"
                  id="phone"
                  placeholder="Your Phone Number"
                  className="p-4 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600 transition-shadow duration-300"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  required
                />
              </div>
            </div>
            
            {/* File Upload */}
            <div 
              {...getRootProps()} 
              className={`mt-2 flex justify-center px-6 pt-5 pb-6 border-2 border-dashed rounded-xl transition-all duration-300 ${
                isDragActive ? 'border-blue-400 bg-blue-50' : 'border-gray-300 hover:border-gray-400'
              }`}
            >
              <div className="space-y-1 text-center">
                <FaCloudUploadAlt className="mx-auto h-12 w-12 text-gray-400" />
                <div className="flex text-sm text-gray-600">
                  <label 
                    htmlFor="file-upload" 
                    className="relative cursor-pointer bg-white rounded-md font-medium text-blue-600 hover:text-blue-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-blue-500"
                  >
                    <span>Upload files</span>
                    <input {...getInputProps()} />
                  </label>
                  <p className="pl-1">or drag and drop</p>
                </div>
                <p className="text-xs text-gray-500">Any file type up to 100MB</p> {/* Updated file size limit and file types */}
              </div>
            </div>

            {/* Uploaded Files List */}
            {files.length > 0 && (
              <ul className="mt-4 space-y-2">
                {files.map((file, index) => (
                  <li 
                    key={index} 
                    className="flex items-center justify-between py-2 px-4 bg-gray-50 rounded-lg transition-all duration-300 hover:bg-gray-100"
                  >
                    <div className="flex items-center">
                      <FaFileAlt className="text-blue-500 mr-2" />
                      <span className="text-sm font-medium text-gray-700">{file.name}</span>
                    </div>
                    <button 
                      type="button" 
                      onClick={() => removeFile(file)} 
                      className="text-red-500 hover:text-red-700 transition-colors duration-300"
                      aria-label={`Remove ${file.name}`}
                    >
                      <FaTrashAlt />
                    </button>
                  </li>
                ))}
              </ul>
            )}

            {/* Additional Instructions */}
            <div className="mt-8">
              <label htmlFor="instructions" className="block text-sm font-medium text-gray-700 mb-2">
                Additional Instructions
              </label>
              <textarea
                id="instructions"
                name="instructions"
                rows="4"
                className="w-full p-4 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-600 focus:border-blue-600 transition-shadow duration-300"
                placeholder="Enter any additional instructions or requirements..."
                value={instructions}
                onChange={(e) => setInstructions(e.target.value)}
              ></textarea>
            </div>

            {/* Order Summary */}
            <div className="mt-8 bg-gray-50 p-6 rounded-xl shadow-inner">
              <h2 className="text-lg font-semibold text-gray-900 mb-4">Order Summary</h2>
              <div className="flex justify-between items-center mb-2">
                <span className="text-gray-600">{serviceDetails.title}</span>
                <span className="font-medium text-gray-900">${serviceDetails.price.toFixed(2)}</span>
              </div>
              <div className="flex justify-between items-center mb-4">
                <span className="text-gray-600">Processing Fee</span>
                <span className="font-medium text-gray-900">$2.00</span>
              </div>
              <div className="border-t border-gray-200 pt-4 flex justify-between items-center">
                <span className="text-lg font-semibold text-gray-900">Total</span>
                <span className="text-xl font-bold text-blue-600">${(serviceDetails.price + 2).toFixed(2)}</span>
              </div>
            </div>

            {/* Submit and Checkout Buttons */}
            <div className="mt-8 flex flex-col sm:flex-row sm:space-x-4">
              <button
                type="submit"
                className={`flex-1 flex justify-center items-center py-3 px-4 border border-transparent rounded-lg shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-colors duration-300 ${
                  isSubmitted ? 'bg-green-600 hover:bg-green-700' : ''
                }`}
                disabled={isSubmitted}
              >
                {isSubmitted ? (
                  <>
                    <FaCheckCircle className="mr-2" />
                    Submitted
                  </>
                ) : (
                  'Submit'
                )}
              </button>
              {isSubmitted && (
                <button
                  type="button"
                  onClick={handleCheckout}
                  className="mt-4 sm:mt-0 flex-1 flex justify-center items-center py-3 px-4 border border-transparent rounded-lg shadow-sm text-sm font-medium text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 transition-colors duration-300"
                >
                  Continue to Checkout
                </button>
              )}
            </div>
          </form>
        </div>
        
        {/* Security and Privacy Notice */}
        <div className="mt-8 text-center text-sm text-white flex items-center justify-center space-x-2">
          <FaInfoCircle className="inline-block" />
          <span>Your information and files are encrypted and secure. We value your privacy.</span>
        </div>
      </div>
    </div>
  );
};

export default UploadPage;
