import React from 'react';
import { Link } from 'react-router-dom';

const Privacy = () => {
  return (
    <div className="min-h-screen bg-gray-100 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-3xl mx-auto bg-white shadow-md rounded-lg overflow-hidden">
        <div className="px-4 py-5 sm:p-6">
          <h1 className="text-3xl font-bold text-gray-900 mb-6">Privacy Policy</h1>
          
          <section className="mb-8">
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">1. Introduction</h2>
            <p className="text-gray-600 mb-4">
              Welcome to AlgoAssist AI ("we", "our", or "us"). We are committed to protecting your personal information and your right to privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our website and services.
            </p>
            <p className="text-gray-600">
              By using our services, you agree to the collection and use of information in accordance with this policy. If you do not agree with our policies and practices, please do not use our services.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">2. Information We Collect</h2>
            <p className="text-gray-600 mb-4">We collect several types of information from and about users of our services, including:</p>
            <ul className="list-disc pl-6 text-gray-600">
              <li>Personal information such as name, email address, and phone number when you create an account or submit assignments.</li>
              <li>Information about your assignments, including file names, content, and submission dates.</li>
              <li>Information about your transactions with us, including payment information.</li>
              <li>Information about your device and internet connection, including your IP address, browser type, and operating system.</li>
            </ul>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">3. How We Use Your Information</h2>
            <p className="text-gray-600 mb-4">We use the information we collect about you to:</p>
            <ul className="list-disc pl-6 text-gray-600">
              <li>Provide, maintain, and improve our services.</li>
              <li>Process your assignments and deliver results.</li>
              <li>Communicate with you about your account and our services.</li>
              <li>Detect, prevent, and address technical issues and fraudulent activities.</li>
              <li>Comply with legal obligations.</li>
            </ul>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">4. Data Security</h2>
            <p className="text-gray-600 mb-4">
              We implement appropriate technical and organizational measures to maintain the security of your personal information, including encryption of data in transit and at rest. However, please be aware that no method of transmission over the internet or electronic storage is 100% secure.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">5. Data Retention</h2>
            <p className="text-gray-600">
              We retain your personal information for as long as necessary to fulfill the purposes outlined in this Privacy Policy, unless a longer retention period is required or permitted by law. We will securely delete or anonymize your information when it is no longer needed for these purposes.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">6. Your Data Protection Rights</h2>
            <p className="text-gray-600 mb-4">Depending on your location, you may have certain rights regarding your personal information, including:</p>
            <ul className="list-disc pl-6 text-gray-600">
              <li>The right to access the personal information we hold about you.</li>
              <li>The right to request the correction of inaccurate personal information.</li>
              <li>The right to request the deletion of your personal information.</li>
              <li>The right to withdraw consent for processing your personal information.</li>
              <li>The right to object to the processing of your personal information.</li>
              <li>The right to data portability.</li>
            </ul>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">7. Third-Party Services</h2>
            <p className="text-gray-600">
              Our services may contain links to third-party websites or services that are not owned or controlled by AlgoAssist AI. We have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party websites or services.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">8. Children's Privacy</h2>
            <p className="text-gray-600">
              Our services are not intended for use by children under the age of 13. We do not knowingly collect personal information from children under 13. If you are a parent or guardian and you believe your child has provided us with personal information, please contact us. 
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">9. Changes to This Privacy Policy</h2>
            <p className="text-gray-600">
              We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page and updating the "Last updated" date. You are advised to review this Privacy Policy periodically for any changes.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">10. Contact Us</h2>
            <p className="text-gray-600">
              If you have any questions about this Privacy Policy, please contact us at:
            </p>
            <ul className="list-disc pl-6 text-gray-600">
              <li>Email: privacy@algoassistai.com</li>
              <li>Address: 123 AI Street, Tech City, TC 12345</li>
            </ul>
          </section>

          <p className="text-gray-600 mt-8">
            Last updated: {new Date().toLocaleDateString()}
          </p>

          <div className="mt-8">
            <Link to="/" className="text-blue-600 hover:text-blue-800 transition duration-300">
              Back to Home
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Privacy;