import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FaCode, FaProjectDiagram, FaPenFancy, FaCheck, FaClock, FaShieldAlt } from 'react-icons/fa';

const PricingCard = ({ title, price, description, features, icon: Icon, onClick }) => (
  <div className="flex flex-col bg-white rounded-2xl shadow-xl transition-all duration-300 hover:shadow-2xl hover:scale-105 overflow-hidden">
    <div className="p-8 bg-gradient-to-br from-blue-50 to-white">
      <div className="flex justify-center mb-4">
        <Icon className="text-5xl text-blue-600" />
      </div>
      <h3 className="text-2xl font-bold text-center text-gray-900 mb-2">{title}</h3>
      <p className="text-3xl font-bold text-center text-blue-600 mb-4">
        ${price}
      </p>
      <p className="text-gray-600 text-center mb-6">{description}</p>
    </div>
    <div className="flex-grow p-6 bg-white">
      <ul className="space-y-4">
        {features.map((feature, index) => (
          <li key={index} className="flex items-center text-gray-700">
            <FaCheck className="text-green-500 mr-3 flex-shrink-0" />
            <span>{feature}</span>
          </li>
        ))}
      </ul>
    </div>
    <div className="p-6 bg-gray-50">
      <button
        onClick={onClick}
        className="w-full bg-blue-600 text-white font-semibold py-3 px-6 rounded-lg hover:bg-blue-700 transition duration-300 transform hover:scale-105 shadow-md hover:shadow-xl"
      >
        Get Started
      </button>
    </div>
  </div>
);

export default function Pricing() {
  const navigate = useNavigate();

  const handleServiceSelection = (service) => {
    navigate(`/upload?service=${service}`);
  };

  const plans = [
    {
      title: "Coding Assignments",
      price: "24.99",
      description: "Perfect for individual coding assignments and homework help",
      icon: FaCode,
      features: [
        "24-hour delivery guarantee",
        "AI-powered solution generation",
        "Expert code review",
        "Detailed explanations",
        "Multiple programming languages",
        "Direct chat support"
      ]
    },
    {
      title: "Coding Projects",
      price: "49.99",
      description: "Comprehensive support for larger coding projects",
      icon: FaProjectDiagram,
      features: [
        "Complete project planning",
        "Architecture design",
        "Full implementation",
        "Documentation included",
        "Testing and optimization",
        "Priority support"
      ]
    },
    {
      title: "Written Assignments",
      price: "19.99",
      description: "Expert assistance with computer science written work",
      icon: FaPenFancy,
      features: [
        "Technical writing support",
        "Research assistance",
        "Citation formatting",
        "Plagiarism check",
        "Grammar review",
        "Unlimited revisions"
      ]
    }
  ];

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-gray-100 py-24 px-4 sm:px-6 lg:px-8">
      {/* Header Section */}
      <div className="max-w-7xl mx-auto text-center mb-16">
        <h1 className="text-4xl font-bold text-gray-900 mb-6">
          Choose Your <span className="text-blue-600">Perfect Plan</span>
        </h1>
        <p className="text-xl text-gray-600 max-w-3xl mx-auto">
          Get expert assistance tailored to your needs with our comprehensive service plans
        </p>
        
        {/* Trust Indicators */}
        <div className="flex justify-center space-x-8 mt-8">
          <div className="flex items-center text-gray-600">
            <FaClock className="text-blue-600 mr-2" />
            <span>24h Delivery</span>
          </div>
          <div className="flex items-center text-gray-600">
            <FaShieldAlt className="text-blue-600 mr-2" />
            <span>100% Secure</span>
          </div>
        </div>
      </div>

      {/* Pricing Cards */}
      <div className="max-w-7xl mx-auto grid gap-8 lg:grid-cols-3 lg:gap-8">
        {plans.map((plan, index) => (
          <PricingCard
            key={index}
            {...plan}
            onClick={() => handleServiceSelection(plan.title.toLowerCase().replace(' ', '-'))}
          />
        ))}
      </div>

      {/* Bottom Trust Banner */}
      <div className="max-w-7xl mx-auto mt-16 p-6 bg-white rounded-xl shadow-md">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 text-center">
          <div className="flex flex-col items-center">
            <div className="text-xl font-semibold text-gray-900 mb-2">Satisfaction Guaranteed</div>
            <p className="text-gray-600">Full refund if you're not satisfied</p>
          </div>
          <div className="flex flex-col items-center">
            <div className="text-xl font-semibold text-gray-900 mb-2">Expert Support</div>
            <p className="text-gray-600">24/7 assistance available</p>
          </div>
          <div className="flex flex-col items-center">
            <div className="text-xl font-semibold text-gray-900 mb-2">Secure Platform</div>
            <p className="text-gray-600">Your data is always protected</p>
          </div>
        </div>
      </div>
    </div>
  );
}