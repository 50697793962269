import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom'; // Add Navigate here
import { UserProvider, useUser } from './UserContext'; // Add useUser import

// Components
import App from './App';
import Login from './Login';
import Dashboard from './Dashboard';
import UploadPage from './UploadPage';
import CheckoutPage from './CheckoutPage';
import ConfirmationPage from './ConfirmationPage';
import Privacy from './privacy';
import Terms from './Terms';
import Learn from './learn';
import OAuthCallback from './OAuthCallback';
import Pricing from './Pricing';
import ProtectedRoute from './ProtectedRoute';

// Styles
import './index.css';
import AdminDashboard from './AdminDashboard';

// Admin Route Component
const AdminRoute = ({ children }) => {
  const { user } = useUser();
  
  if (!user || user.role !== 'admin') {
    return <Navigate to="/" replace />;
  }
  
  return children;
};

// Protected Routes Configuration
const protectedRoutes = [
  {
    path: '/dashboard',
    element: Dashboard
  },
  {
    path: '/upload',
    element: UploadPage
  },
  {
    path: '/checkout',
    element: CheckoutPage
  }
];

// Admin Routes Configuration
const adminRoutes = [
  {
    path: '/admin',
    element: AdminDashboard,
    title: 'Admin Dashboard'
  }
];

// Public Routes Configuration
const publicRoutes = [
  {
    path: '/',
    element: App
  },
  {
    path: '/login',
    element: Login
  },
  {
    path: '/pricing',
    element: Pricing
  },
  {
    path: '/confirmation',
    element: ConfirmationPage
  },
  {
    path: '/privacy',
    element: Privacy
  },
  {
    path: '/terms',
    element: Terms
  },
  {
    path: '/learn/:service',
    element: Learn
  },
  {
    path: '/oauth-callback',
    element: OAuthCallback
  }
];

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <UserProvider>
      <Router>
        <Routes>
          {/* Render Public Routes */}
          {publicRoutes.map(({ path, element: Element }) => (
            <Route key={path} path={path} element={<Element />} />
          ))}

          {/* Render Protected Routes */}
          {protectedRoutes.map(({ path, element: Element }) => (
            <Route
              key={path}
              path={path}
              element={
                <ProtectedRoute>
                  <Element />
                </ProtectedRoute>
              }
            />
          ))}

          {/* Render Admin Routes */}
          {adminRoutes.map(({ path, element: Element, title }) => (
            <Route
              key={path}
              path={path}
              element={
                <ProtectedRoute>
                  <AdminRoute>
                    <Element />
                  </AdminRoute>
                </ProtectedRoute>
              }
            />
          ))}

          {/* 404 Page - Keep this last */}
          <Route
            path="*"
            element={
              <div className="min-h-screen flex items-center justify-center bg-gray-50">
                <div className="text-center">
                  <h1 className="text-4xl font-bold text-gray-800 mb-4">404</h1>
                  <p className="text-xl text-gray-600">Page not found</p>
                </div>
              </div>
            }
          />
        </Routes>
      </Router>
    </UserProvider>
  </React.StrictMode>
);